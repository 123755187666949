import { DirectusFields } from '@/common/constants/directus-fields';
import { Collections } from '@/common/interfaces/collection.interface';
import { normalizeStore } from '@/pages/inventory-page/normalization';
import { ApiType } from '@/services/api';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getStoreDataAction,
  getStoreSuccessDataAction,
  storeErrorAction,
  updateStoreAction,
} from '../reducers/store.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import { Store, StoreData } from '../types/stores';

function* getStore(api: ApiType, action: ReturnType<typeof getStoreDataAction>) {
  try {
    const response: TransportResponse<StoreData> = yield call(
      api.getCollectionItemById,
      Collections.Stores,
      action.payload,
      { fields: DirectusFields.Store },
    );

    if (!response?.data) {
      throw new Error('No data received from API');
    }

    const normalizedStore: Store = normalizeStore(response.data);

    yield put(getStoreSuccessDataAction(normalizedStore));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(storeErrorAction());
  }
}

function* updateStore(api: ApiType, action: ReturnType<typeof updateStoreAction>) {
  try {
    const response: TransportResponse<StoreData> = yield call(
      api.updateCollectionItem,
      Collections.Stores,
      action.payload,
    );

    if (!response?.data) {
      throw new Error('Failed to update store');
    }

    const normalizedStore: Store = normalizeStore(response.data);

    yield put(getStoreSuccessDataAction(normalizedStore));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(storeErrorAction());
  }
}

export const storeSaga = function* (api: ApiType) {
  yield all([
    takeLatest(getStoreDataAction.type, getStore, api),
    takeLatest(updateStoreAction.type, updateStore, api),
  ]);
};
