import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../types/stores';

export interface StoreState {
  isLoading: boolean;
  data?: Store;
}

const initialState: StoreState = {
  isLoading: false,
  data: undefined,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    getStoreDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getStoreSuccessDataAction: (state, action: PayloadAction<Store>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    updateStoreAction: (state, action: PayloadAction<Store>) => {
      state.data = action.payload;
    },
    storeErrorAction: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  getStoreDataAction,
  getStoreSuccessDataAction,
  updateStoreAction,
  storeErrorAction,
} = storeSlice.actions;

export const storesReducer = storeSlice.reducer;
