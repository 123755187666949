import {
  YarnColorCollectionItem,
  YarnManufacturerCollectionItem,
} from '@/common/interfaces/collection.interface';
import {
  Inventory,
  InventoryData,
  Product,
  ProductData,
  Store,
  StoreData,
  SupplierData,
  YarnColorData,
} from '@/store/types/stores';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';

function normalizeSupplier(supplier: SupplierData): YarnManufacturerCollectionItem {
  return supplier.Yarn_manufacturer_id;
}

function normalizeYarnColor(yarnColorData: YarnColorData): YarnColorCollectionItem {
  return {
    id: yarnColorData.id,
    image: getAssetBackendUrl(yarnColorData.image, 400),
    ean: yarnColorData.ean,
    eanImage: yarnColorData.eanImage,
    name: yarnColorData.name,
  };
}

function normalizeProduct(productDataObject: ProductData): Product {
  const productData = productDataObject.products_id;
  return {
    id: productData.id,
    quantity: productData.quantity,
    price: productData.price,
    yarnColor: normalizeYarnColor(productData.yarn_color),
    yarn: productData.yarn_color.yarn,
  };
}

function normalizeInventory(inventoryData: InventoryData): Inventory {
  return {
    id: inventoryData.inventory_id.id,
    products: inventoryData.inventory_id.product.map(normalizeProduct),
  };
}

export function normalizeStore(storeData: StoreData): Store {
  return {
    id: storeData.id,
    name: storeData.name,
    inventories: storeData.inventory.map(normalizeInventory),
    suppliers: storeData.supplier.map(normalizeSupplier),
    address: storeData.address || '',
    phone: storeData.phone || '',
    email: storeData.email || '',
    opening_hours: storeData.opening_hours || '',
  };
}
